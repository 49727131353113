const appConfig = {
  "version": "1.3.0-release+105",
  "stage": "release",
  "amplify": {
    "region": "us-east-1",
    "userPoolId": "us-east-1_14cFcpuS8",
    "userPoolWebClientId": "2ang6ft5ouukrfqm3njh4fds70",
    "oauth": {
      "domain": "atlas-auth-test.mit.edu",
      "scope": [
        "profile",
        "openid",
        "elo/user",
        "digital-id/search",
        "digital-id/user"
      ],
      "redirectSignIn": "https://elo-tracking-test.mit.edu/",
      "redirectSignOut": "https://elo-tracking-test.mit.edu/logout",
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  },
  "signInProvider": "Touchstone",
  "api": {
    "hostname": "https://api-test.mit.edu",
    "paths": {
      "elo": "elo-tracking-v1",
      "digital-id": "digital-id-v1"
    }
  }
};
export default appConfig;